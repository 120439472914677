@font-face {
  src: url("./fonts/HelveticaNeueLTGEO-75Bold.ttf");
  font-family: "helvetica-bold";
}

@font-face {
  src: url("./fonts/HelveticaNeueLTGEO-55Roman.ttf");
  font-family: "helvetica-normal";
}

a,
p,
span,
button,
i,
li,
label {
  font-family: helvetica-normal !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: helvetica-bold !important;
  font-feature-settings: "case" on;
}