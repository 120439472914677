:root { 
--gray_51:#fafafa; 
--cyan_900_33:#00476b33; 
--red_700:#d51f3a; 
--cyan_900_cc:#00476bcc; 
--red_600:#ee2737; 
--red_900:#d00027; 
--cyan_900_98:#00476b98; 
--white_A700_90:#ffffff90; 
--light_green_200:#c3e397; 
--cyan_900_8e:#00476b8e; 
--red_500:#ff3a44; 
--gray_50:#f9f9fc; 
--white_A700_33:#ffffff33; 
--teal_600:#009d68; 
--gray_700_59:#66666659; 
--yellow_600:#f7de28; 
--black_900:#000000; 
--cyan_900_c3:#00476bc3; 
--cyan_900_a1:#00556aa1; 
--teal_600_cc:#009d68cc; 
--cyan_900_4c:#00476b4c; 
--cyan_900_a9:#00476ba9; 
--red_A700:#cb0606; 
--gray_700_cc:#666666cc; 
--red_500_cc:#ff3a44cc; 
--gray_600:#858585; 
--gray_700:#666666; 
--black_900_0a:#0000000a; 
--teal_600_b2:#009d68b2; 
--cyan_900_00:#00476b00; 
--gray_901:#111111; 
--red_A100:#fb8d8d; 
--gray_900:#252525; 
--cyan_900_dc:#00476bdc; 
--black_900_0f:#0000000f; 
--black_900_0c:#0000000c; 
--gray_300:#dee0e5; 
--gray_100:#f6f6f6; 
--bluegray_900:#333333; 
--cyan_900_b7:#00466bb7; 
--white_A700_dd:#ffffffdd; 
--gray_900_71:#11111171; 
--cyan_900:#00476b; 
--white_A700:#ffffff; 
--cyan_900_b8:#00476bb8; 
}